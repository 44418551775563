<template>
    <v-form v-if="dataSource" class="wrapperForm">
        <v-row dense>
            <v-col cols="12" sm="12" md="12" class="section-wrapper">

                <v-card flat v-for="(adjustment, i) in dataSource" :key="i">

                    <v-card-text>

                        <div class="form-box-title title-with-right-part">

                            <span>{{$t("Лист_согласования")}} {{i+1}} {{$t("от_время")}}  {{dateFormat(adjustment.CreateDate, 'DD.MM.YYYY')}}</span>

                            <div class="twrp-content">
                                
                                <i class="fas fa-print" @click="onPrintClick(adjustment.id)"></i>

                                <div class="vertical-divider"></div>
                                <span class="result-label">{{$t("Результат_согласования")}}:</span>

                                <StatusChip :status="GetAvaibleStatuses[$helpers.getAdjustmentStatus(adjustment.StatusCode)]"/>

                            </div>

                        </div>

                        <v-row class="full-width-row" no-gutters>
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{$t("Инициатор")}}</label>
                            </v-col>
                            <v-col  cols="12" sm="12" md="10">
                                <div class="onlyReadData more-per-lab-wrap">

                                    <v-employee-chip :id="adjustment.InitiatorID" :name="adjustment.InitiatorName" />

                                    <template v-if="adjustment.IsWithdrawn">
                                        <span>({{$t("Согласование_отменено_инициатором")}})</span>
                                    </template>

                                </div>
                            </v-col>
                        </v-row>

                        <v-row class="full-width-row" no-gutters>
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{$t("Порядок_согласования")}}</label>
                            </v-col>
                            <v-col  cols="12" sm="12" md="10">
                                <div class="onlyReadData">
                                    {{ $t(GetAdjustmentTypes.find(x => x.id === adjustment.Parallel).Value) }}
                                </div>
                            </v-col>
                        </v-row>

                        <v-row class="full-width-row" no-gutters>
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{$t("Согласующие")}}</label>
                            </v-col>
                            <v-col  cols="12" sm="12" md="10">

                                <div class="onlyReadData more-per-lab-wrap">

                                    <template v-for="(adjuster, index) in adjustment.Records">

                                        <v-workplace-chip 
                                            :key="adjuster.id" 
                                            :id="adjuster.SignerID" 
                                            :name="adjuster.SignerName" 
                                            :iconColor="adjusterStatusColor(adjuster.StatusCode)" 
                                            :canDelete="canCancelAdjustmentMember(adjustment, adjuster)"
                                            :delFunc="onCancelAdjusment"
                                            :textLineThrough = "adjuster.StatusCode == 5"
                                        />

                                        <v-icon v-if="!adjustment.Parallel && adjustment.Records.indexOf(adjuster) +1 != adjustment.Records.length"
                                            :key="index"
                                            :color="adjuster.StatusCode == 2 ? '#228ac7': ''"
                                            small
                                        >
                                            fas fa-angle-right
                                        </v-icon>

                                    </template>

                                </div>

                            </v-col>
                        </v-row>

                        <v-row class="full-width-row" no-gutters v-if="adjustment.Records.filter(x => x.StatusCode != 1).length > 0">

                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{$t("Результаты_согласования")}}</label>
                            </v-col>
                            <v-col  cols="12" sm="12" md="10">
                                <template v-for="(adjuster, index) in adjustment.Records.filter(x => x.StatusCode != 1)">

                                    <div :key="index" class="onlyReadData more-per-lab-wrap">

                                        <span>{{dateFormat(adjuster.SignDate, 'DD.MM.YYYY HH:mm:ss')}}</span>

                                        <v-workplace-chip :id="adjuster.SignerID" :name="adjuster.SignerName" />

                                        <StatusChip :status="GetAvaibleStatuses[$helpers.getAdjustmentStatus(adjuster.StatusCode)]"/>

                                        <span v-if="adjuster.Comment">{{$t("с_комментарием")}}: {{adjuster.Comment}}</span>

                                        <v-chip v-if="adjuster.Attachment"
                                            class="person-label file-label"
                                            small
                                            label
                                        >
                                            <i class="fas fa-file-alt"></i>
                                            <div>{{adjuster.Attachment.Name}}</div>
                                            <i class="fas fa-download" @click="onDownload(adjuster.Attachment.id, adjuster.Attachment.Name)"></i>
                                        </v-chip>

                                    </div>

                                </template>
                            </v-col>
                            
                        </v-row>

                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system"
import StatusChip from '@/components/global/chips/StatusChip'

export default {
    name: "adjustments-tab",
    props: {
        id: {
            type: String,
            default: null,
            opened: []
        }
    },
    components: {
        StatusChip,
    },
    computed: {
        ...mapGetters('documents/references', ['GetAdjustmentTypes', 'GetAvaibleStatuses']),
        ...mapGetters('global/auth', ["getUserInfo"]),
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        dateFormat: sys.dateFormat,
        async update() {
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/tabs/adjustments/${this.$props.id}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'isCommon': this.$store.getters['global/actionsource/isDataSourceCommon'] },
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.dataSource = response?.data?.Payload;
        },
        async onPrintClick(listId) {

            this.$store.dispatch('setOverlayVisible', { visible: true });

            let response = await httpAPI({
                url: `api/download/report?type=Chancellery|Documents.AdjustmentList&id=${listId}`,
                method: 'GET',
                responseType: 'blob', 
            });

            this.$store.dispatch('setOverlayVisible', { visible: false });

            if (response) {
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

                await this.$store.dispatch('dialogs/pdfView/open', { url, name: this.$t(`Лист_согласования`) });
                
                URL.revokeObjectURL(url);
            }
        },
        async onDownload(attachId, attachName) {
            this.$eventBus.$emit('on-download-file', { id: attachId, name: attachName });
        },
        adjusterStatusColor(status) {
            switch(status)
            {
                case 2:
                    return "#43B581";
                
                case 4:
                    return "#FAA61A";

                case 3:
                    return "#F04747";

                default:
                    return null;
            }
        },
        canCancelAdjustmentMember(list, member){
            return list.StatusCode == 1 && list.Records.filter(x => x.StatusCode == 1 || x.StatusCode == 2).length > 1 && member.StatusCode == 1 && list.InitiatorWorkPlaceID == this.getUserInfo.workplaceId;
        },
        async onCancelAdjusment(id) {
            let adjuster = null;
            for (let i = 0; i < this.dataSource.length || !adjuster; i++) {
                for (let j = 0; j < this.dataSource[i].Records.length || !adjuster; j++) {
                    if (this.dataSource[i].Records[j].SignerID === id){
                        adjuster = this.dataSource[i].Records[j];
                    }
                }
            }
            this.$notify.confirm(
                this.$t('Вы_действительно_хотите_удалить_согласующего_value.message',{ value: adjuster?.SignerName}),
                async () =>
                {
                    this.setOverlayVisible({ visible: true, text: `Удаление_согласующего...` });
                    await this.$store.dispatch(`global/actionsource/CancelAdjusment`, {id:id, overlay:false});
                    this.setOverlayVisible({ visible: false });
                }
            );
        },
    },
    async created() {
        await this.update();
    },
    mounted() {        
        this.$eventBus.$on('update-active-tab', async () => {
            if (this.$parent.isActive)
                await this.update();
        });
    },
    destroyed() {
        this.$eventBus.$off('update-active-tab');
    }
}
</script>
